import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  eventDate,
  eventTitle,
  eventDesc,
  siteTitle,
  menuItem,
  images,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";
import {
  Button,
  TimePicker,
  Input,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useRef } from "react";

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>Hotel & Travel</p>
    </div>
  );
};

const TravelPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <p className={eventDate}>Vincci EverEden Beach Resort</p>
      <p className={eventDesc}>
        <div>
          Vincci EverEden is located in the Athenian Riviera 30-35 minutes from
          the Athens International Airport and 1 hour from the city center. To reserve your stay for our wedding, use the <strong style={{ fontWeight: "bold" }}>
            VineetandPriyankaWedding
          </strong>{" "} booking code at this <a
            href="https://en.vinccievereden.com/bookcore/availability/rooms/evereden?cp=VineetandPriyankaWedding&adults=2&babies=0&checkin=2025-05-25&checkout=2025-05-28"
            target="_blank"
          >
            link (please note: the correct page may load on the second attempt)
          </a>{" "} for a minimum 15% discount between May 24-29, 2025 which can be
          combined with other offers. This booking code is only valid until
          April 24, 2025 so please make sure to book in advance.
          <br />
          <br />
          We also recommend checking third-party sites like{" "}
          <a
            href="https://www.booking.com/hotel/gr/ever-eden-beach-resort-hotel.html?aid=304142&label=gen173rf-1FCAEoggI46AdIM1gDaE2IAQGYATG4ARjIAQ_YAQHoAQH4AQKIAgGiAg5sb2NhbGhvc3Q6ODAwMKgCBLgCo_SPvAbAAgHSAiQwMzA4YmVlNC1iMDQ2LTQzZmYtYjJkNy1jYTZlZTk4YTI3ZTbYAgXgAgE&sid=65d9788bd5d8f3f66a8b9ba423281cfc&all_sr_blocks=4756601_0_2_1_0&checkin=2025-05-25&checkout=2025-05-28&dest_id=-813948&dest_type=city&dist=0&group_adults=2&group_children=0&hapos=1&highlighted_blocks=4756601_0_2_1_0&hpos=1&matching_block_id=4756601_0_2_1_0&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA&sb_price_type=total&sr_order=popularity&sr_pri_blocks=4756601_0_2_1_0__84791&srepoch=1736702628&srpvid=229b7a4f9d96016b&type=total&ucfs=1&"
            target="_blank"
          >
            Booking.com
          </a>,{" "}
          <a
            href="https://www.agoda.com/ever-eden-beach-resort-hotel/hotel/anavyssos-gr.html?countryId=197&finalPriceView=2&isShowMobileAppPrice=false&cid=1844104&numberOfBedrooms=&familyMode=false&adults=2&children=0&rooms=1&maxRooms=0&checkIn=2025-05-25&isCalendarCallout=false&childAges=&numberOfGuest=0&missingChildAges=false&travellerType=1&showReviewSubmissionEntry=false&currencyCode=EUR&isFreeOccSearch=false&los=3&searchrequestid=25b98f4d-863e-4598-993d-166f5cc72263"
            target="_blank"
          >
            Agoda
          </a>, etc...
          to compare prices and find the best deal.
        </div>
      </p>
      <p className={eventDate}>Athens International Airport</p>
      <p className={eventDesc}>
        Athens International Airport is located in Athens, Greece and is by far
        the busiest in the country. Delta, British Airways, Air Berlin,
        Lufthansa, Air France, American Airlines, United, Aeroflot, and many
        more international airlines have direct flights from US cities to
        Athens. Please fill out the travel itinerary form below to provide your
        travel details.
      </p>
      <p align="center">
        <iframe
          style={{
            margin: "auto",
            display: "block",
            maxWidth: "200%",
            maxHeight: "100%",
          }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSdk0dcYQcdHkl_zbmBHe3F-NlCu3SJQCRt1X9BFS71DCbC5XQ/viewform?embedded=true"
          width="640"
          height="1681"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </p>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};
export default TravelPage;
