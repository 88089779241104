import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  eventDate,
  eventTitle,
  menuItem,
  images,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";

// markup
const IndexPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      <p className={eventDate} style={{ fontSize: "42px" }}>
        Traditions & Outfit Guide
      </p>
      <p>
        All guests are encouraged to wear what makes them feel comfortable,
        whether that's traditional Indian attire or Western wedding attire.
        Below is a guide for those interested in traditional clothing options.
        There is no expectation to wear Indian attire for all four events, or
        any of them, if that is your preference! Feel free to mix and match
        based on your style and comfort for each celebration.
      </p>
      <p className={eventTitle}>Mehendi & Sangeet</p>
      <p>
        The Mehendi is a vibrant tradition that involves the application of
        henna paste to create intricate and temporary designs on the skin.
        Guests can get their mehendi designs done with the henna artist!
      </p>
      <p>
        Sangeet means song in Sanskrit. The ceremony involves singing and
        dancing with the couple, their close family and friends to celebrate the
        onset of their new journey together. During the Sangeet, family and
        friends perform choreographed songs that flows into a dance party. If
        you want to be a part of the choreographed dance performances let us
        know.
      </p>
      <p
        style={{
          justifyContent: "center",
          fontStyle: "italic",
          fontSize: "larger",
        }}
      >
        Dress Code: Festive Indian or Western party attire
      </p>
      <p>
        Women can wear lehengas, anarkalis, or sarees (consider pre-pleated for
        ease). Lighter fabrics like chiffon, georgette, and crepe are ideal for
        dancing. You could also go with Indo-Western gown or western cocktail
        dresses. Men can wear a kurta with a Nehru jacket or bandhgala. If you'd
        prefer Western wear, a suit or dress shirt with dress pants works well.
        Make sure your shoes are comfortable for dancing!
      </p>
      <StaticImage
        alt="vineet"
        src="../images/sangeet.jpeg"
        style={{ display: "block" }}
        className={images}
      />
      <hr />
      <p className={eventTitle}>Mangalasnanam/Haldi</p>
      <p>
        In Telugu culture, the Haldi (called Pellikuthuru for the bride and
        Pellikoduku for the groom) and Mangalasnanam are festive pre-wedding
        rituals. The Haldi involves friends and family applying a paste made
        from turmeric, sandalwood, and other auspicious ingredients onto the
        bride and groom. The yellow color of the paste symbolizes prosperity and
        happiness and is believed to ward off evil spirits while blessing the
        couple with a blissful married life.
      </p>
      <p>
        The Mangalasnanam is a ceremonial bath for the bride and groom, using
        water infused with turmeric, milk, honey, and aromatic herbs. Family
        members and elders bless the couple during this ritual by pouring the
        blessed water over them. The Mangalasnanam is a sacred start to the
        wedding day and the spiritual cleansing symbolizes purification, and the
        beginning of a new chapter in the couple's life.
      </p>
      <p
        style={{
          justifyContent: "center",
          fontStyle: "italic",
          fontSize: "larger",
        }}
      >
        Dress Code: Yellow, white, light colored casual attire
      </p>
      <p>
        This is typically a casual, relaxed ceremony, so light and easy-to-clean
        outfits are best, as it can get messy. Women can wear sarees, churidars,
        or Indo-Western outfits. For those opting for Western wear,
        light-colored sundresses, jumpsuits, or maxi dresses. Men can wear
        kurta-pajamas in cotton or linen. If you prefer Western attire, a
        light-colored button-down shirt with chinos or linen pants would be
        perfect for this occasion.
      </p>
      <StaticImage
        alt="vineet"
        src="../images/haldi.png"
        style={{ display: "block" }}
        className={images}
      />
      <hr />
      <p className={eventTitle}>Pelli Ceremony</p>
      <p>
        The Pelli is the Telugu word for a traditional wedding ceremony. It is
        considered the strongest of social bonds, spiritually merging two souls.
        It is sanctified by the seven vows made by the bride and groom, which
        are symbolized by seven revolutions around a sacred fire. Various
        symbolic gestures and rituals are performed to ensure that the bride and
        groom are united in the presence of the Panchabhutaalu—the five
        essential elements of life: Bhumi (earth), Akaasham (sky), Agni (fire),
        Neeru (water), and Vaayuvu (air). The ceremony is held under a Kalyana
        Mandapam (wedding pavilion), beautifully decorated with fresh flowers.
        Each element in the ceremony holds deep symbolic meaning and is treated
        with great reverence.
      </p>
      <p
        style={{
          justifyContent: "center",
          fontStyle: "italic",
          fontSize: "larger",
        }}
      >
        Dress Code: Indian Formal or Western Formal Attire
      </p>
      <p>
        Avoid wearing black and white on the big day (as white is traditionally
        worn during Hindu funerals). Women's traditional options include silk
        sarees, like Kanjeevaram or Banarasi. If you're new to sarees,
        pre-pleated options or silk-blend and soft cotton sarees, lehengas, or
        anarkalis are more manageable. For a Western option, formal gowns or
        floral dresses. Men can wear a silk or cotton-silk kurta (with a dhoti
        for a more authentic look) or a formal sherwani in shades like beige,
        gold, or cream. Adding a traditional angavastram (scarf) over the
        shoulder adds a South Indian touch. For a Western option, men can wear a
        suit.
      </p>
      <StaticImage
        alt="vineet"
        src="../images/pelli.png"
        style={{ display: "block" }}
        className={images}
      />
      <hr />
      <p className={eventTitle}>Wedding Reception</p>
      <p>
        You know what a wedding reception is, a lively celebration filled with
        delicious food, music, and dancing!
      </p>
      <p
        style={{
          justifyContent: "center",
          fontStyle: "italic",
          fontSize: "larger",
        }}
      >
        Dress Code: Formal Indian or Western Attire
      </p>
      <p>
        The reception is an opportunity for a more glamorous, modern look to
        celebrate the evening in style. If you're interested in traditional
        Indian attire, women can wear lehengas or saree gowns, and men can opt
        for a bandhgala or sherwani. If you'd like to go with Western wear, a
        formal dress for women, and a suit or tuxedo for men, would be perfect.
      </p>
      <StaticImage
        alt="vineet"
        src="../images/reception.png"
        style={{ display: "block" }}
        className={images}
      />
      <hr />

      <p className={eventDate}>Where to Shop for Traditional Indian Attire</p>

      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: "1",
            alignItems: "center",
          }}
        >
          <p
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "larger",
              textDecoration: "underline",
            }}
          >
            Online Retailers
          </p>
          <ul
            style={{
              listStyleType: "none",
              textAlign: "center",
              alignContent: "center",
              padding: "0",
            }}
          >
            <li>
              <a
                href="https://www.kalkifashion.com"
                target="_blank"
                rel="noopener"
              >
                Kalki Fashion
              </a>
            </li>
            <li>
              <a
                href="https://www.andaazfashion.com"
                target="_blank"
                rel="noopener"
              >
                Andaaz Fashion
              </a>
            </li>
            <li>
              <a
                href="https://www.houseofindya.com"
                target="_blank"
                rel="noopener"
              >
                House of Indya
              </a>
            </li>
            <li>
              <a
                href="https://www.lashkaraa.com"
                target="_blank"
                rel="noopener"
              >
                Lashkaraa
              </a>
            </li>
            <li>
              <a
                href="https://www.shopnavaa.com"
                target="_blank"
                rel="noopener"
              >
                Etsy - Indian Wear
              </a>
            </li>
            <li>
              <a
                href="https://www.amazon.com/indian-wear/s?k=indian+wear"
                target="_blank"
                rel="noopener"
              >
                Amazon - Indian Wear
              </a>
            </li>
          </ul>
        </div>

        <div
          style={{
            flex: "1",
            alignItems: "center",
          }}
        >
          <p
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "larger",
              textDecoration: "underline",
            }}
          >
            Rental Options
          </p>
          <ul
            style={{
              listStyleType: "none",
              textAlign: "center",
              alignContent: "center",
              padding: "0",
            }}
          >
            <li>
              <a
                href="https://www.sarisandthings.com"
                target="_blank"
                rel="noopener"
              >
                Saris and Things
              </a>
            </li>
            <li>
              <a
                href="https://borrowthebazaar.com/collections/catalog"
                target="_blank"
                rel="noopener"
              >
                Borrow the Bazaar
              </a>
            </li>
            <li>
              <a href="https://www.allborrow.com" target="_blank">
                All Borrow
              </a>
            </li>
          </ul>
        </div>
      </div>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default IndexPage;
