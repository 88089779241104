import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  eventDate,
  eventDesc,
  siteTitle,
  menuItem,
  images
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";

// markup
const FAQ = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <div className={container}>
      <div>
        {isTabletOrMobile && MobileContent()}
        {/* { MobileContent() } */}
      </div>
      <Header></Header>
      {/* <p className={eventDate}>Where should I book a hotel room in Athens?</p>
      <p className={eventDesc}>We have a hotel block at ..... Use the code to get the hotel block discount.</p>
      <br /> */}
      <p className={eventDate}>What should I wear to the wedding?</p>
      <p className={eventDesc}>Check out our traditions & outfits guide for each event on the Traditions & Outfits tab. </p>
      <br />
      <p className={eventDate}>What currency is used in Athens, Greece?</p>
      <p className={eventDesc}>The official currency is the Euro (€). It is advisable to have some local currency for small expenses, but credit cards are widely accepted.</p>
      <br />
      <p className={eventDate}>What are the travel requirements for attending the wedding in Athens, Greece?</p>
      <p className={eventDesc}>Don't forget your passports! Please ensure that your passport is valid for at least six months beyond your planned departure date from Greece. For US passport holders, a travel visa is NOT required for stays less than 90 days. https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/Greece.html/</p>
      <p className={eventDesc}>Important Information for US and UK Passport Holders: Starting in 2025, US and UK passport holders will need an ETIAS travel authorization to enter EU countries, including Greece. At this time ETIAS is currently not in operation and no applications are collected at this point. (https://travel-europe.europa.eu/etias_en) The official start date for this requirement has not yet been confirmed, but we’ll keep you updated as more information becomes available. You can apply online through the official ETIAS website or mobile app. The process is quick, and most applications are approved within minutes. The cost is €7. In most cases, approval is instant. However, it may take up to 4 days or longer (up to 30 days) if additional information or an interview is required. Once approved, your ETIAS is valid for 3 years or until your passport expires, whichever comes first. It allows stays of up to 90 days within a 180-day period in the EU. So please be sure to apply well in advance of your trip. The passport you use to apply for ETIAS must be the same one you carry when traveling. Your ETIAS is linked to this passport, and discrepancies will prevent you from boarding or entering the EU.</p>
      <br />
      <p className={eventDate}>Can I bring a guest?</p>
      <p className={eventDesc}>Due to limited space, we are only able to accommodate those guests formally invited on your wedding invitation. If you received a plus one they will appear on your invite as well as when you RSVP. Thank you for understanding!</p>
      <br />
      <p className={eventDate}>What is the weather like?</p>
      <p className={eventDesc}>Greece has a Mediterranean climate, with a beautiful spring, summer season. Average high temperature in Athens is 74.8 degrees Fahrenheit in May.</p>
      <br />
      <p className={eventDate}>How do I travel to Athens?</p>
      <p className={eventDesc}>Athens International Airport is located in Athens, Greece and is by far the busiest in the country. Delta, British Airways, Air Berlin, Lufthansa, Air France, American Airlines, United, Aeroflot, and many more international airlines have direct flights from US cities to Athens.</p>
      <p className={eventDate}>How do get from the airport to the hotel?</p>
      <p className={eventDesc}>The airport is about a 30-40 minute taxi ride away from the hotel. Please let us know your travel details on the form found on the Hotel & Travel tab.</p>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};

const MobileContent = () => {
  return (
    <div>
      <div className={menuItem} id="outer-container">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </div>
      <p className={siteTitle}>WEDDING ITINERARY</p>
    </div>
  );
};

export default FAQ;